import React from 'react'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Component = (props) => {
  const search = useLocation().search
  const [data, setData] = React.useState()

  const getData = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
      previewKey
        ?`https://bestclassic.microcms.io/api/v1/playlists?draftKey=${previewKey}`
        : 'https://bestclassic.microcms.io/api/v1/playlists',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setData(result.data.contents)
    console.log('Playlists data were successfully downloaded.')
  },[search]) 

  React.useEffect( ()=>{
    getData()
  },[getData])

  return (
    <section className="playlist">
      <div className="inner1024">
        <div className="commonHead tac">
          <div className="inner1024">
            <div className="en fs_18 ff_mecken text-grad gold2">Playlist</div>
            <div className="ja fs_24 ff_shserif"><h2><span className="border after">プレイリスト</span></h2></div>
          </div>{/* inner1024 */}
        </div>{/* commonHead */}
        <div className="intro tac">
          イメージ・キャラクター鈴木光がおすすめするプレイリストです。<br/>日常の生活シーンに合わせてお楽しみください。
        </div>
        <div className="the_list">
          <div className="wrap flex">
            { data && data.map( item => (
              <div className="item" key={item.title}>
                { item.url
                  ?
                  <a href={item.url} target= '_blank' rel='noopener noreferrer' >
                   <picture>
                    <source media="(max-width: 767px)" srcSet={item.image.url+'?fit=max&w=480&h=480'}/>
                    <img className="-img--noresize" src={item.image.url + '?fit=max&w=240&h=240'} alt={item.title}/>
                    </picture>
                  </a>
                  : 
                  <picture className='disable'>
                    <source media="(max-width: 767px)" srcSet={item.image.url+'?fit=max&w=480&h=480'}/>
                    <img className="-img--noresize" src={item.image.url + '?fit=max&w=240&h=240'} alt={item.title}/>
                  </picture> 
                 }
              </div>
            ))}
          </div>{/* wrap */}
        </div>{/* the_list */}
      </div>{/* inner1024 */}
    </section>
  )
}

export default Component