import React from 'react'

const Component = ({selected, type, changeParentState, setAlbum}) => {
  const closeModal = () => {
    return changeParentState(false)
  }
  const selectAlbum = album =>{
    return setAlbum(album)
  }
  return (
    <div data-remodal-id="artist"  className='remodal'>
      <button onClick={()=>closeModal()} data-remodal-action="close" className="modal_close before after"/>
      <div className="container">
        <div className="body">
          <div className="wrap flex -alstart tal -spcolumn">
            <div className="item portrait image">
              <img src={ selected.image_croped
                                ? selected.image_croped.url+'?fit=max&w=480&h=640'
                                :  selected.image.url+'?fit=max&w=480&h=640'}
                        alt={selected && selected.name}/>

            {type === 'celebrity' && selected.profile &&  // 著名人のみプロフィールここに表示
              <div className="detail fs_13 ff_yugo profile pc"><h5>{selected.name}</h5><br/>{selected.profile}</div>
            }
            </div>

            <div className="item info">
              { type === 'artist' && // アーティスト
                <React.Fragment>
                  <div className="name">
                    <div className="index fs_18 ff_shserif">{selected && selected.name}</div>
                  </div>
                  <div className="description fs_14 ff_yugo">
                    {selected.description}
                  </div>
                  <div className="title">
                    <div className="index fs_18 ff_shserif">関連アルバム</div>
                  </div>
                  <div className="list">
                    {selected && selected.albums && selected.albums.map(item => (
                      <div
                        onClick={()=>selectAlbum(item)}
                        key={item.title_en}
                        className="item wrap flex -jsstart">
                        <div className="thumbnail">
                          <picture>
                            <source media="(max-width: 767px)" srcSet={( item.image && item.image.url+'?fit=max&w=96&h=96' )}/>
                            <img src={( item.image && item.image.url+'?fit=max&w=48&h=48' ) || "img/img_artist_1.jpg"} alt=""/>
                          </picture>
                          <div className="num fs_12 ff_yugo">{item.number}</div>
                        </div>{/* thumbnail */}
                        <div className="song ff_shserif">
                          <div className="title fs_12">{item.artist}</div>
                          <div className="lower flex">
                            <div className="name fs_14">{item.title}</div>
                          </div>{/* lower */}
                        </div>{/* info */}
                        <div className="play tac ff_nsan fs_14 tal">
                          <a href={item && item.url} target= '_blank' rel='noopener noreferrer' >再生・購入</a>
                        </div>
                      </div>
                    ))}
                  </div>{/* list */}
                </React.Fragment>
              }
              { type === 'celebrity' && // 著名人
              <React.Fragment>
              {/* <div className="name">
                <div className="index fs_18 ff_shserif">{selected && selected.name}</div>
              </div> */}
                <div className="name">
                  <div className="index fs_18 ff_shserif">私のベスト・クラシック100極</div>
                </div>
                <div className="list">
                  {selected && selected.selecitons && selected.selecitons.map(item => (
                    <React.Fragment>
                      <div
                        onClick={()=>selectAlbum(item.album)}
                        key={item.album.title_en}
                        className="item wrap flex -jsstart">
                        <div className="thumbnail">
                          <picture>
                            <source media="(max-width: 767px)" srcSet={( item.album.image && item.album.image.url+'?fit=max&w=64&h=64' )}/>
                            <img src={( item.album.image && item.album.image.url+'?fit=max&w=32&h=32' ) || "img/img_artist_1.jpg"} alt=""/>
                          </picture>
                          <p className="num fs_14 ff_yugo tac">{item.album.number}</p>
                        </div>{/* thumbnail */}
                        <div className="song ff_shserif">
                          <div className="title">{item.album.artist}</div>
                          <div className="lower flex">
                            <div className="name fs_12">{item.album.title}</div>
                          </div>{/* lower */}
                        </div>{/* info */}
                        <div className="play tac ff_nsan fs_14 tal">
                          <a href={item.album && item.album.url} target= '_blank' rel='noopener noreferrer' >再生・購入</a>
                        </div>
                      </div>
                      <p className="fs_14 ff_yugo comment">{item.comment}</p>
                    </React.Fragment>
                  ))}
                </div>{/* list */}

                { selected.profile && 
                  <div className="detail fs_13 ff_yugo profile sp"><h5>{selected.name}</h5><br/>{selected.profile}</div>
                }
              </React.Fragment>
              }
            </div>
          </div>{/* wrap */}
        </div>{/* body */}
      </div>{/* container */}
    </div>
  )
}

export default Component
