import React from 'react'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal'
import ModalArtist from './ModalArtist'
import ModalAlbum from './ModalAlbum'
import {modalStyle} from '../css/ModalStyle'
Modal.setAppElement("#root");

const Component = () => {
  const search = useLocation().search
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState()
  const [selectedArtist, setSelectedArtist] = React.useState()
  const [selectedAlbum, setSelectedAlbum] = React.useState()

  const closeModal = () => {
    setIsOpen(false)
    document.body.removeAttribute('style', 'overflow: hidden;')
  }
  const openModal = album => {
    setSelectedArtist(album)
    setIsOpen(true)
    document.body.setAttribute('style', 'overflow: hidden;')
  }
  const toggleModal = album => {
    modalIsOpen === true
      ? closeModal()
      : openModal(album)
  }

  const getData = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
      previewKey
      ? `https://bestclassic.microcms.io/api/v1/celebrities?draftKey=${previewKey}&limit=20`
      : 'https://bestclassic.microcms.io/api/v1/celebrities?limit=20',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setData(result.data.contents)
    console.log('Celebrities data were successfully downloaded.', result.data)
  },[search]) 

  React.useEffect( ()=>{
    getData()
  },[getData])


  return (
    <section className="chosen">
      <div className="inner1024">
        <div className="commonHead tac">
          <div className="inner1024">
            <div className="en fs_18 ff_mecken text-grad gold2">My Personal Choice</div>
            <div className="ja fs_24 ff_shserif">
              <h2>
                <span className="border after">
                  著名人が選ぶ<br/>ベスト・クラシック100極
                  {/* <div className="sortby fs_12">五十音順</div> */}
                </span>
              </h2>
            </div>
          </div>{/* inner1024 */}
        </div>{/* commonHead */}
        
        <div className="body">
          <div className="wrap flex -wrap c4">
            {data && data.map ( item => (
              <div
                key={item.name}
                className="item"
                onClick={()=>{
                  toggleModal(item)
                }}
              >
                <div className="image">
                  <picture>
                    <source media="(max-width: 767px)" srcSet={item.image.url+'?fit=max&w=360&h=480' }/>
                    <img className="-img--noresize" src={item.image.url+'?fit=max&w=360&h=480' } alt={item.name}/>
                  </picture>
                </div>
                <div className="bottom flex -spcolumn">
                  <div className="name fs_18 ff_shserif">{item.name}</div>
                </div>
              </div>
            ))}
          </div>{/* wrap */}
        </div>{/* body */}
        <Modal isOpen={modalIsOpen} className="modal_data" onRequestClose={()=>closeModal()} shouldCloseOnOverlayClick={true} style={modalStyle}>
          { selectedAlbum
            ?
              <ModalAlbum
                selected={selectedAlbum}
                previousButton={true}
                changeParentState={()=>closeModal()} 
                backToArtist={()=>{setSelectedAlbum()}}
              />
            :
              <ModalArtist
                selected={selectedArtist}
                type="celebrity"
                changeParentState={()=>closeModal()}
                setAlbum={(album)=>{setSelectedAlbum(album)}} />
          }
        </Modal>
      </div>{/* inner1024 */}
    </section>
  )
}

export default Component
