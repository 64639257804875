import React from 'react'
import { Link } from 'react-scroll'

const Component = () => {
  const [expanded, setExpanded ] = React.useState(false)
  return (
    <section className={expanded === true ? "quality open" : "quality"}>
      <div className="inner1024">
        
        <div className="commonHead tac">
          <div className="inner1024">
            <div className="en fs_18 ff_mecken text-grad gold2">Our sound Quality</div>
            <div className="ja fs_24 ff_shserif"><h2><span className="border after">極HiFi CD</span></h2></div>
          </div>{/* inner1024 */}
        </div>{/* commonHead */}
        
        <div className="body">
          <div className="upper flex c2 -alstart -spcolumn">
            <div className="start">
              <div className="copy fs_28 ff_shserif">
                <div className="catch">
                  <h3>音楽の本質に迫る、<br className="sp"/>ゆらぎのない音。<br/>
                    ありのままに伝わる、<br/>
                    音楽の感動を今ここに。</h3>
                </div>
                <div className="wave">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="img/img_wave_quality@2x.png"/>
                    <img className="-img--noresize" src="img/img_wave_quality.png" alt="wave"/>
                  </picture>
                </div>
              </div>
            </div>
            <div className="end fs_18">
              「マスターテープに忠実なサウンド」を求めて、様々な「高品質CD」がリリースされている昨今。<br/>
              私達はさらなる高みを目指し、このたび新たに一層原音に忠実な高品質CD「極HiFi CD(きわみはいふぁいCD)」を完成させました。ソニー・ミュージックが誇る膨大なライブラリーからよりすぐった100タイトルのクラシックCDシリーズ「BEST CLASSICS  100極」で初めて採用されたこの高品質技術は、マスターテープに込められた感動をひとつぶ残さずリスナーに届けるための理想的なCDとなっております。
            </div>
          </div>{/* upper */}
          
          <div className={expanded === false ? "lower" : "lower open"} id='lower'>
            
            <div className="label tac fs_24">
              <h3><span className="text-grad gold2">極HiFi CDについて</span></h3>
            </div>
            <div className="read flex c2 -alstretch -spcolumn">
              <div className="start fs_14">
                
                <div className="sp images tac">
                  <div className="image i1 flex -jscenter">
                    <div className="box">
                      <img src="img/img_waves_normal@2x.jpg" alt="通常CD"/>
                      <div className="caption fs_12">通常CD</div>
                    </div>
                    <div className="box">
                      <img src="img/img_waves_kiwamihifi@2x.jpg" alt="極HiFiCD"/>
                      <div className="caption fs_12">極HiFiCD</div>
                    </div>
                  </div>{/* i1 */}
                </div>
                
                <p>高品質CDを追求するなかで、ディスク成形プロセスの改善という新しいアプローチによりHF(HighFrequency)パターンを明瞭にすることでジッター(時間軸のゆらぎ)を改善しました。今回の「極HiFi CD」では、次のような技術改善によってマスターに記録されたサウンドをこれまで以上に忠実に再現することを可能としています。</p>
                
                <dl>
                  <dt>■金型改善</dt>
                  <dd>
                    通常のCD型では充填された樹脂が効率よく冷却される構造としていますが、「極HiFi CD」型は音楽信号が刻まれた原盤を取付ける金型表面に低熱伝導率のコーティングを施すこ とで溶解樹脂を徐冷させ、音楽情報を記録しているピット/ランド と呼ばれる凹凸形状の隅々にまで樹脂を行き渡らせる技術を採用しています。
                  </dd>
                  <dt>■成形条件改善</dt>
                  <dd>
                    高い金型温度と長い冷却時間とすることで転写性を最大限まで向上させました。また最適な型締パターンの採用により、一層の転写性向上と面内の転写ムラを極めて小さくしました。 原盤を忠実に複製することで正確な信号再現性が期待できます。また、転写性の向上に加え再生系に 影響を与えるディスクの反りや複屈折(光学特性)も大きく改善させました。
                  </dd>
                  <dt>■反射膜改善</dt>
                  <dd>
                    CDに使われているレーザー反射膜に、通常CDに使われるアルミニウム合金よりも格段に高い反射率の銀合金を採用。照射されるレーザーを正確に捉えることによってマスターに忠実性の高い音楽の再現が望めます。
                  </dd>
                </dl>
              </div>{/* start */}
              <div className="end tac">
                <div className="image i1 flex -jscenter">
                  <div className="box pc">
                    <img src="img/img_waves_normal.jpg" alt="通常CD"/>
                    <div className="caption fs_12">通常CD</div>
                  </div>
                  <div className="box pc">
                    <img src="img/img_waves_kiwamihifi.jpg" alt="極HiFiCD"/>
                    <div className="caption fs_12">極HiFiCD</div>
                  </div>
                </div>{/* i1 */}
                <div className="image i1 flex -jscenter">
                  <div className="box">
                    <picture>
                      <source media="(max-width: 767px)" srcSet="img/img_graph_1@2x.jpg"/>
                      <img src="img/img_graph_1.jpg" alt="ピット転写率"/>
                    </picture>
                    <div className="caption fs_12">ピット転写率</div>
                  </div>
                  <div className="box">
                    <picture>
                      <source media="(max-width: 767px)" srcSet="img/img_graph_2@2x.jpg"/>
                      <img src="img/img_graph_2.jpg" alt="反射率"/>
                    </picture>
                    <div className="caption fs_12">反射率</div>
                  </div>
                </div>{/* i1 */}
              </div>{/* end */}
            </div>{/* read */}
            
          </div>{/* lower */}
          
          <div className="button tac ff_nsan sp">
            {expanded === true
              ? <Link to='quality' smooth={false} className="ico_arrow val"onClick={()=>setExpanded(!expanded)}>Less</Link>
              : <span className= "ico_arrow val" onClick={()=>setExpanded(!expanded)}>More</span>
            }
          </div>
          
        </div>{/* body */}
        
      </div>{/* innr1024 */}
    </section>
  )
}

export default Component
