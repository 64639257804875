import React from 'react'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal'
import Title from './SectionTitle'
import ModalAlbum from './ModalAlbum'
import {Collapse} from 'react-collapse';
import {modalStyle} from '../css/ModalStyle'

const Component = () => {
  const search = useLocation().search
  const [expanded, setExpanded] = React.useState({})
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [selected, setSelected] = React.useState()
  const [data, setData] = React.useState()

  const closeModal = () => {
    setIsOpen(false)
    document.body.removeAttribute('style', 'overflow: hidden;')
  }
  const openModal = album => {
    setSelected(album)
    setIsOpen(true)
    document.body.setAttribute('style', 'overflow: hidden;')
  }
  const toggleModal = album => {
    modalIsOpen === true
      ? closeModal()
      : openModal(album)
  }

  const getData = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
      previewKey
        ?`https://bestclassic.microcms.io/api/v1/buyers?draftKey=${previewKey}`
        : 'https://bestclassic.microcms.io/api/v1/buyers',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setData(result.data.contents)
    console.log('Buyers data were successfully downloaded.')
  },[search])

  React.useEffect( ()=>{
    getData()
  },[getData])


  return (
    <React.Fragment>
    <section className="titles">
      <div className="inner1024">
        <Title ja='バイヤー＆メディアおすすめタイトル' en="Buyer’s &amp; Media’s Choice"/>      
        <div className="body">
          {data && data.map( buyer => (
            <div
              key={buyer.buyer}
              className={expanded[buyer.buyer] === true ? "item open" : "item"}
            >
              <div className="org flex -jsstart" onClick={()=>setExpanded({...expanded, [buyer.buyer]: !expanded[buyer.buyer] })}>
                <div className="thumbnail">
                  <picture>
                    <source media="(max-width: 767px)" srcSet={buyer.image.url+'?fit=max&w=166&h=166'}/>
                    <img src={buyer.image.url+'?fit=max&w=88&h=88'} alt="TOWER RECORDS"/>
                  </picture>
                </div>
                <div className="info ff_shserif">
                  <div className="org fs_24">{buyer.buyer}</div>
                </div>
                <div className="open">
                  <div className="button after before"></div>
                </div>
              </div>
              <Collapse isOpened={expanded[buyer.buyer] || false} className="accordion">
                <div className="list">
                  {buyer.selections.map( select => (
                    <div key={select.album.title_en} className="song flex -jsstart">
                      <div className="item" onClick={()=>{
                        toggleModal(select.album)
                      }}>
                        <div className="each flex -jsstart">
                          <div className="thumbnail">
                            {select.album.image &&
                              <picture>
                                <source media="(max-width: 767px)" srcSet={select.album.image.url+'?fit=max&w=80&h=80'}/>
                                <img src={select.album.image.url+'?fit=max&w=40&h=40'} alt=""/>
                              </picture>
                            }
                          </div>
                          <div className="num fs_14 ff_yugo">{select.album.number}</div>
                          <div className="info ff_shserif">
                            <div className="title">{select.album.artist}</div>
                            <div className="lower flex">
                              <div className="name fs_12">{select.album.title}</div>
                            </div>{/* lower */}
                          </div>{/* info */}
                          <div className="play tac ff_nsan fs_14">
                            { select.album.url &&
                              <a href={select.album.url } target= '_blank' rel='noopener noreferrer' >再生・購入</a>
                            }
                          </div>
                        </div>
                        <div className="description fs_14">
                          {select.comment}
                        </div>
                        <div className="who ff_shserif fs_14">{select.name}</div>
                      </div>{/* item */}
                    </div>
                  ))}
                </div>{/* list */}
              </Collapse>{/* accordion */}
            </div>
          ))}
        </div>{/* body */}
        
      </div>{/* inner1024 */}
    </section>
    <Modal isOpen={modalIsOpen} className="modal_data" onRequestClose={()=>closeModal()} shouldCloseOnOverlayClick={true} style={modalStyle}>
      <ModalAlbum selected={selected} changeParentState={()=>closeModal()} />
    </Modal>
    </React.Fragment>
  )
}

export default Component
