import React from 'react'
import moment from 'moment'
import 'moment-timezone'
moment.tz.setDefault('Asia/Tokyo');

const Component = ({selected, changeParentState, previousButton, backToArtist}) => {
  const closeModal = () => {
    return changeParentState(false)
  }
  const backModal = () => {
    return backToArtist()
  }
  return (
    <div data-remodal-id="lineup" className='remodal'>
      <button onClick={() => closeModal()} data-remodal-action="close" className="modal_close before after"/>
      { previousButton===true &&
        <button onClick={() => backModal()} data-remodal-action="close" className="modal_pre before after"/>
      }
      <div className="container">
        <div className="commonHead tac">
          <div className="en fs_14 ff_mecken text-grad gold2">{selected && selected.title_en}</div>
          <div className="ja fs_18 ff_shserif"><div className="title"><span className='num ff_yugo'>{selected && `${selected.number}`}</span>{selected.title}</div></div>
          <div className="name">{selected && selected.artist}</div>
        </div>{/* commonHead */}
        <div className="body">
          <div className="wrap flex -alstart tal -spcolumn">
            <div className="item player spotify">
              <img src={selected && selected.image.url+'?fit=max&w=300&h=380'} alt={selected && selected.title_en}/>
              { selected && selected.isSpotifyActive === true  && selected.spotify 
                ? <iframe src=  {selected && selected.spotify.replace('/album/','/embed/album/')} width="300" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media" title={selected && selected.title_en}/>
                : moment().isSameOrAfter(moment('2020/11/25'),'day') === true && selected.spotify &&
                  <iframe src=  {selected && selected.spotify.replace('/album/','/embed/album/')} width="300" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media" title={selected && selected.title_en}/>
              }
            </div>
            <div className="item info">
              <div className="description fs_14 ff_yugo">
                {selected && selected.includings.replace('[演奏]','\n\n[演奏]')}
              </div>{/* includings */}
              <br/>
              <div className="description fs_14 ff_yugo">
                {selected && selected.recording}
              </div>{/* recording */}
              <div className="play tac ff_nsan fs_14 tal">
                <div className='subinfo ff_yugo fs_14'>
                  <p>
                    {selected && selected.code &&
                      selected.code + '　'
                    }
                    {selected && selected.price &&
                      selected.price + '　'
                    }
                    {selected && selected.format &&
                      selected.format 
                    }
                  </p>
                </div>
                <a href={selected && selected.url} target= '_blank' rel='noopener noreferrer' >再生・購入</a>
              </div>
              <div className="detail fs_14 ff_yugo">
                {selected && selected.description}
              </div>{/* description */}
            </div>{/* item */}
          </div>{/* wrap */}
        </div>{/* body */}
      </div>{/* container */}
    </div>
  )
}

export default Component

