import React from 'react'
import axios from 'axios'

const Component = ({changeParentState})=> {
  const [data, setData] = React.useState()

  const getData = React.useCallback(async()=>{
    const result = await axios.get(
      'https://bestclassic.microcms.io/api/v1/answers',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setData(result.data.contents)
    console.log('Sample Quiz data were successfully downloaded.')
  },[]) 

  React.useEffect(()=>{
    getData()
  },[getData])

  const closeModal = () => { changeParentState(false) }
  return (
    <div data-remodal-id="bestclassicquiz"  className='remodal'>
      <button onClick={() => closeModal()} data-remodal-action="close" className="modal_close before after"/>
      <div className="container">
        {data && data.map( answer => (
        <div className="samplequiz tac ff_yugo" key={answer.title}>
          <img src={answer.image.url} alt={answer.title}/>
          <div className="title">{answer.title}</div>
          <div className="answer fs_20">{answer.answer}</div>
        </div>
        ))}
      </div>
    </div>
  )
}

export default Component