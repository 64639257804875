import React from 'react'
import { Link } from 'react-scroll'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {Collapse} from 'react-collapse';
import Modal from 'react-modal'
import Title from './SectionTitle'
import ModalArtist from './ModalArtist'
import ModalAlbum from './ModalAlbum'
import {modalStyle} from '../css/ModalStyle'
Modal.setAppElement("#root");

const Component = () => {
  const search = useLocation().search
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const [selectedArtist, setSelectedArtist] = React.useState()
  const [selectedAlbum, setSelectedAlbum] = React.useState()
  const [data, setData] = React.useState()

  const closeModal = () => {
    setIsOpen(false)
    setSelectedAlbum()
    document.body.removeAttribute('style', 'overflow: hidden;')
  }
  const openModal = album => {
    setSelectedArtist(album)
    setIsOpen(true)
    document.body.setAttribute('style', 'overflow: hidden;')
  }
  const toggleModal = album => {
    modalIsOpen === true
      ? closeModal()
      : openModal(album)
  }

  const getData = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
      previewKey
        ?`https://bestclassic.microcms.io/api/v1/artists?limit=54&draftKey=${previewKey}`
        : 'https://bestclassic.microcms.io/api/v1/artists?limit=54',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setData(result.data.contents)
    console.log('Artist data were successfully downloaded.')
  },[search]) 

  React.useEffect( ()=>{
    getData()
  },[getData])


  const artist = (item) => (
    <div
      key={item.name}
      className="item"
      onClick={()=>{
        toggleModal(item)
      }}>
      <div className="image">
        <picture>
          <source media="(max-width: 767px)" srcSet={
            item.image_croped 
              ? item.image_croped.url+'?fit=max&w=360&h=480' 
              : item.image.url+'?fit=max&w=360&h=480' 
            }/>
          <img src={
            item.image_croped 
              ? item.image_croped.url+'?fit=max&w=360&h=480' 
              : item.image.url+'?fit=max&w=360&h=480' 
            } alt={item.name} />
        </picture>
      </div>
      <div className="name"><h3>{item.name.length < 12 ? item.name: item.name.replace('・', '・\n')}</h3></div>
    </div>
  )

  return (
    <section className="artists">
      <div className="inner1024">
        <Title ja='アーティスト' en='Artists' />
        <div className="body">
          <div className="wrap flex -alstretch -wrap">
            { data && data.slice(0, 10).map ( item => (
              artist(item)
            ))}
          </div>{/* wrap */}
          <div id='backLink'/>
          <Collapse isOpened={expanded} >
            <div className={expanded === true ? "wrap additional open  flex -alstretch -wrap" :  "wrap additional  flex -alstretch -wrap"}>
                { data && data.slice(10, 54).map( item => artist(item)) }
            </div>
          </Collapse>{/* additional */}
          <div className="showall tac">
            <span className="button ff_nsan fs_14">
              {expanded === true
               ? <Link to='backLink' smooth={false} className="ico_arrow val reverse"onClick={()=>setExpanded(!expanded)}>Less</Link>
              : <span className= "ico_arrow val" onClick={()=>setExpanded(!expanded)}>More</span>
              }
            </span>
          </div>{/* showall */}
        </div>{/* body */}
      </div>{/* inner1024 */}
      <Modal isOpen={modalIsOpen} className="modal_data" onRequestClose={()=>closeModal()} shouldCloseOnOverlayClick={true} style={modalStyle}>
        {selectedAlbum
        ? 
          <ModalAlbum
            selected={selectedAlbum}
            previousButton={true}
            changeParentState={()=>closeModal()} 
            backToArtist={()=>{setSelectedAlbum()}} />
        :
          <ModalArtist
            selected={selectedArtist}
            type="artist"
            changeParentState={()=>closeModal()}
            setAlbum={(album)=>{setSelectedAlbum(album)}} />
        }
      </Modal>
    </section>
  )
}

export default Component
