import React from 'react'
import axios from 'axios'
import Title from './SectionTitle'
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Query
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Component = () => {
  const search = useLocation().search
  const [movies, setMovies] = React.useState()

  const getMovies = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
			previewKey
				? `https://bestclassic.microcms.io/api/v1/movies?filters=showOnGallery[equals]true&draftKey=${previewKey}`
      	: 'https://bestclassic.microcms.io/api/v1/movies?filters=showOnGallery[equals]true',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setMovies(result.data.contents)
    console.log('Movies data were successfully downloaded.')
  },[search]) 

  React.useEffect( ()=>{
    getMovies()
  },[getMovies])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className="movie">    
      <Title ja='ムービーギャラリー' en='Movie Gallery' />
      <div className="body">
        <div className="inner">
          <Slick {...settings}>
            {movies && movies.map(movie => (
              <div
                key={movie.title}
                className="item">
                <div className="video">
                  <div className="frame">
                    <iframe width="560" height="315" src={movie.url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={movie.title}/>
                  </div>
                  <div className="caption tac ff_nserif fs_18">{movie.title}</div>
                </div>
              </div>
            ))}
          </Slick>
        </div>{/* inner1024 */}
      </div>{/* body */}
    </section>
  )
}

export default Component
