import React from 'react'

const Component = props => {
  return (
    <div className="commonHead tac">
      <div className="inner1024">
        <div className="en fs_18 ff_mecken text-grad gold2">{props.en}</div>
        <div className="ja fs_24 ff_shserif"><h2><span className="border after">{props.ja}</span></h2></div>
      </div>{/* inner1024 */}
    </div>
  )
}

export default Component
