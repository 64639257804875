import React from 'react'
import { QuizContext } from "../services/QuizProvider";

const Component = () => {
  const [quizContents,] = React.useContext(QuizContext)
  const thisLevel = quizContents.questions.filter( q => q.level_id === quizContents.level)[0]
  const total = thisLevel.random
  const correctAnswersCount = quizContents.results.filter(r => r===true).length
  const isSuccess = thisLevel.threshold <= correctAnswersCount
  const resultText = () => {
    return isSuccess === true
      ? `合格`
      : `努力賞`
  }
  const resultImageUrl = () => (
    isSuccess === true
    ? thisLevel.image_success.url
    : thisLevel.image_failure.url
  )
  const resutTweetUrl = () => (
    isSuccess === true
    ? thisLevel.tweet_success_url
    : thisLevel.tweet_failure_url
  )

  return (
    <div className="certification green container">
      <div className="head">
        <div className="commonHead tac">
          <div className="en fs_18 ff_shserif text-grad gold2">鈴木光があなたに挑戦</div>
          <div className="ja fs_24">ベスクラ・クイズ</div>
        </div>{/* commonHead */}
      </div>{/* head */}
      
      <div className="tier tac">
        <div className="ff_shserif label text-grad gold2">{`${thisLevel.level}・${resultText()}`}</div>
        <div className="trophy">
          {/* <img src="img/img_quiz_trophy.png" alt="トロフィー"/> */}
          <a target= '_blank' rel='noopener noreferrer' href={resultImageUrl()}>
            <img src={resultImageUrl()} alt={resultText()}/>
          </a>
        </div>
      </div>{/* tier */}
      
      <div className="rate ff_mecken">
        <span className="you fs_32">{correctAnswersCount}</span>/{total}<span className="ff_nsan">問正解</span>
      </div>{/* rate */}
    
      <div className="tweet select ff_shserif">
        <a href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(`鈴木光に挑戦！ベスクラクイズ${thisLevel.level}で${resultText()}をもらいました！ ${resutTweetUrl()}`)}&hashtags=${encodeURIComponent('ベスクラクイズ')}`} className="twitter-share-button" data-text={encodeURIComponent(`鈴木光に挑戦！ベスクラクイズ${thisLevel.level}で${resultText()}をもらいました！`)} data-hashtags={encodeURIComponent('ベスクラクイズ')} data-lang="ja" data-dnt="true" data-show-count="false" target= '_blank' rel='noopener noreferrer' ><div className="border after">結果をツイート</div></a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
      </div>{/* tweet */}

      {/* <div className="close select ff_shserif">
        <div className="border after btn" onClick={finish}>閉じる</div>
      </div> */}
      
    </div>
  )
}

export default Component