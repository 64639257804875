import React from 'react'
import axios from 'axios'
import axiosJsonpAdapter from 'axios-jsonp'

const Component = () => {
  const [news, setNews] = React.useState()
  window.callback = (json) => {
    setNews(json.items)
    console.log('News data were successfully downloaded.')
  }

  React.useEffect(()=>{
    axios.get('https://www.sonymusic.co.jp/json/v2/PR/bestclassics100kiwami/information/start/0/count/4', {
      adapter: axiosJsonpAdapter
    })
  },[])

  return (
    <section className="information ff_shserif">
      <div className="inner1024">
        <div className="wrap flex c2 -spcolumn">
          {news && news.map( item => (
            <article key={item.title}>
              <a href={'https://www.sonymusic.co.jp'+item.link} target= '_blank' rel='noopener noreferrer' >
                <div className="date">{item.date}</div>
                <div className="title  ff_yugo">{item.title}</div>
              </a>
            </article>
          ))}
        </div>{/* wrap */}
      </div>{/* inner1024 */}
    </section>
  )
}

export default Component
