import React from 'react'
import { QuizContext } from "../services/QuizProvider";

const Component = () => {
  const [quizContents,setQuizContents] = React.useContext(QuizContext)
  const setLevel = level => {
    setQuizContents({...quizContents, level: level, step:1})
  }
  return (
    <div className="levels green container">
      <div className="head">
        <div className="commonHead tac">
          <div className="en fs_18 ff_shserif text-grad gold2">鈴木光があなたに挑戦</div>
          <div className="ja fs_24">ベスクラ・クイズ</div>
          <div className="message fs_16">難易度を選択してください</div>
        </div>{/* commonHead */}
      </div>{/* head */}
      <div className="select tac">
        {quizContents.questions && quizContents.questions.map( q=>
          <div onClick={()=>{setLevel(q.level_id)}} className='level-btn' key={q.level_id}>
            <div className="border after">{`${q.level} (全${q.random}問)`}</div>
          </div>
        )}
      </div>{/* select */}
    </div>
  )
}

export default Component