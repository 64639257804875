import React from 'react'
import {Collapse} from 'react-collapse'
import Title from './SectionTitle'

const Component = () => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <section className="renewal">
      <div className="inner1024">
        <Title ja='クラシック音楽の世界遺産100枚、4年ぶりの全面リニューアル' en='Renewal for the First Time in 4 Years' />
        <div className="body">
          <div className="description tac">
              <div className="excerpt">
                <p>世代を超えたクラシックの歴史的名盤から<br className="pc"/>
                時代を映す現代の話題盤までソニー・クラシカルが誇る素晴らしいアーティストたちの芸術を最新技術による最高の品質と、<br className="pc"/>
                お求めやすい価格でお届けする『ベスト・クラシック100極』。<br/>
                略して「ベスクラ」と称される、古くはアナログLP時代に遡るこの人気シリーズが、4年ぶりにリニューアルされました。</p>
              </div>
              <div className={expanded===true ? 'open the_content' : 'the_content'}>
                <Collapse isOpened={expanded}>
                  <p>ソニー・クラシカルとRCAレッド・シールといういずれも100年以上の歴史を誇る<br className="pc"/>世界的な2大メジャー・レーベルの豊富なカタログから<br className="pc"/>極め付きの名盤が100枚、勢ぞろいしました。</p>
                  <p>全タイトルを今回のベスクラ専用に開発された新たな高品質CD「極HiFiCD」でリリースし、<br className="pc"/>しかも盤面には「音匠レーベル」を採用することで内容的にも品質的にも、<br className="pc"/>まさに至高の100タイトルをご提供します。</p>
                  <p>グレン・グールド、ブルーノ・ワルター、レナード・バーンスタイン、<br className="pc"/>アルトゥール・ルービンシュタイン、ヤッシャ・ハイフェツといった、<br className="pc"/>クラシック音楽の演奏史を綺羅星のごとく彩ってきた大スターたちの最高のパフォーマンスをはじめ<br className="pc"/>テオドール・クルレンツィスやパーヴォ・ヤルヴィ、カティア・ブニアティシヴィリなど<br className="pc"/>21世紀のクラシック音楽界を担っている旬のアーティストの最新名盤も数多く投入しています。</p>
                  <p>クラシック音楽の世界遺産とも言うべき『ベスト・クラシック100極』。</p>
                  <p>ごゆっくりお楽しみください。</p>
                </Collapse>
              </div>
              <div className="button ff_nsan">
                <span className="ico_arrow val" onClick={()=>setExpanded(!expanded)}>{expanded === false ? 'More' : 'Less'}</span>
              </div>
          </div>{/* description */}
          <div className="image">
            <img src="img/img_renewal.png" alt="世界遺産100枚"/>
          </div>{/* image */}
        </div>{/* body */}
      </div>{/* inner1024 */}
    </section>
  )
}

export default Component
