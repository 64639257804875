import React from 'react'
import Modal from 'react-modal'
import {modalStyle} from '../css/ModalStyle'
import ModalQuiz from './ModalQuizController'
import ModalAnser from './ModalAnswer'
import QuizProvider from '../services/QuizProvider'
Modal.setAppElement("#root");

const Component = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [modalType, setModalType] = React.useState()
  const openModal = () => {
    setIsOpen(true)
    document.body.setAttribute('style', 'overflow: hidden;')
  }
  const closeModal = () => {
    setIsOpen(false)
    document.body.removeAttribute('style', 'overflow: hidden;')
  }
  return (
    <section className="quiz">
      <div className="inner1024">
        <div className="wrap flex -alend -jscenter -spcolumn">
          <div className="main">
            <div className="head">
              <div className="bg tac">
                <div className="sub fs_24">鈴木光があなたに挑戦！</div>
                <div className="title">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="img/ttl_quiz@2x.png"/>
                    <img src="img/ttl_quiz.png" alt="ベスクラ・クイズ"/>
                  </picture>
                </div>
              </div>
            </div>
            <div className="info flex ff_nsan">
              <div className="image">
                <picture>
                  <img src="img/img_quiz_info.jpg" alt=""/>
                </picture>
              </div>{/* image */}
              <div className="text fs_1">
                クラシック音楽に関する問題を鈴木光が<br className="pc"/>
                あなたに出題。初級・中級・上級・超上級から<br className="pc"/>
                選択し、チャレンジしてください。<br className="pc"/>成績に応じて、鈴木光から<br className="pc"/>
                <strong>ベスクラ「極」デジタル賞状</strong>を授与します。
              </div>
            </div>{/* sample */}
            <div className="baloon sample after">
              <div className="label tac ff_nsan">例題</div>
              <div className="cont">
                <iframe width="186" height="119" src="https://www.youtube.com/embed/oASdz7xFWfo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='ベスクラクイズPC'></iframe>
              </div>
            </div>{/* baloon */}
            <div className="challenge tac ff_nsan fs_28 pc">
              <span className='challenge--btn' onClick={()=>{
                openModal()
                setModalType('quiz')
              }}>クイズをスタート！</span>
              <span className='challenge--btn answer fs_14' onClick={()=>{
                  openModal()
                  setModalType('answer')
              }}>広告動画クイズの答えはこちら！</span>
            </div>
            <div className="challenge tac ff_nsan sp">
              {/* <div className="baloon sample after">
                <div className="label tac ff_nsan">例題</div>
                <div className="cont">
                  <iframe width="186" height="119" src="https://www.youtube.com/embed/oASdz7xFWfo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='ベスクラクイズSP'/>
                </div>
              </div>*/}
              <div className="tac ff_nsan fs_28">
                <span className='challenge--btn' onClick={()=>{
                  openModal()
                  setModalType('quiz')
                }}>クイズをスタート！</span>
                <span className='challenge--btn answer fs_14' onClick={()=>{
                  openModal()
                  setModalType('answer')
                }}>広告動画クイズの答えはこちら！</span>
              </div>
            </div>
          </div>{/* main */}
          <div className="character pc">
            <img src="img/img_hikaru_quiz.png" alt="鈴木光"/>
          </div>{/* character */}
        </div>{/* wrap */}
        
        <Modal isOpen={modalIsOpen} className="modal_data" onRequestClose={()=>closeModal()} shouldCloseOnOverlayClick={false} style={modalStyle}>
          { modalType === 'quiz'
            ?<QuizProvider><ModalQuiz changeParentState={()=>closeModal()}/></QuizProvider>
            : <ModalAnser changeParentState={()=>closeModal()}/>
          }
        </Modal>{/* remodal */}
        
      </div>{/* inner1024 */}
    </section>
  )
}

export default Component
