import React from 'react'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal'
import Title from './SectionTitle'
import AlbumList from './AlbumListSP'
import ModalAlbum from './ModalAlbum'
import MediaQuery from "react-responsive"
import {modalStyle} from '../css/ModalStyle'

Modal.setAppElement("#root");

const Component = () => {
  const search = useLocation().search
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [selected, setSelected] = React.useState()
  const [albums, setAlbums] = React.useState()
  const [fixedAlbums, setFixedAlbums] = React.useState()
  const [expanededAlbumId, setExpandedAlbumId] = React.useState()

  const openModal = album => {
    setSelected(album)
    setIsOpen(true)
    document.body.setAttribute('style', 'overflow: hidden;')
  }
  const closeModal = () => {
    setIsOpen(false)
    document.body.removeAttribute('style', 'overflow: hidden;')
  }
  const toggleModal = album => {
    modalIsOpen === true
      ? closeModal()
      : openModal(album)
  }

  const getAlbums = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
      previewKey
      ? `https://bestclassic.microcms.io/api/v1/albums?orders=number&limit=100&draftKey=${previewKey}`
      : 'https://bestclassic.microcms.io/api/v1/albums?orders=number&limit=100',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setAlbums(result.data.contents)
    setFixedAlbums(result.data.contents)
    console.log('Albums data were successfully downloaded.')
  },[]) 
  
  const filterAlbums = e => {
    const text= e.target.value
    if (text) {
      const filterdAlbums = fixedAlbums.filter( a => 
        a.artist.indexOf(text) !== -1 ||
        a.artist_en.indexOf(text) !== -1 ||
        a.title.indexOf(text) !== -1  ||
        a.title_en.indexOf(text) !== -1 ||
        a.remarks.split(',').filter( r => r.indexOf(text) !== -1 ).length > 0
      )
      setAlbums(filterdAlbums)
    }
    else {
      setAlbums(fixedAlbums)
    }
  }

  React.useEffect( ()=>{
    getAlbums()
  },[getAlbums])

  return (
    <React.Fragment>
      <section className="lineup">
        <div className="inner1024">
        <Title ja='ラインナップ' en='List of Albums' />
          <div className="table pc player">
            <div className="thead">
              <table>
                <thead className="ff_shserif">
                  <tr>
                    <td>
                      <div className="index flex search">
                      <input type="text" placeholder="アーティスト・アルバム・作曲家など" onChange={e=>filterAlbums(e)}/>
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="tbody">
              <table>
                <tbody>
                  {albums && albums.map( album => (
                    <tr
                      key={album.title_en}
                      onClick={()=>{
                        toggleModal(album)
                    }}>
                      <td>
                        <div className="value head tac">
                          { album.image &&
                            <div className="thumbnail"><img src={album.image.url+'?fit=full&w=80&h=80' || "img/img_thumbnail_lineup.png"} alt={album.title}/></div>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="value">
                          <div className="num fs_14 ff_yugo">{album.number}</div>
                        </div>
                      </td>
                      <td>
                        <div className="value">
                          <div className="name">
                            <div className="ja"><h3>{album.artist}</h3></div>
                            <div className="en fs_12">{album.artist_en}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="value">
                          <div className="title">
                            <div className="ja"><h4>{album.title}</h4></div>
                            <div className="en fs_12">{album.title_en}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="value">
                          <div className="play tac ff_nsan fs_14">
                            {album.url && <a href={album.url} target= '_blank' rel='noopener noreferrer' >再生・購入</a>}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>{/* table */}
        </div>{/* inner1024 */}
      </section>
      <Modal isOpen={modalIsOpen} className="modal_data" onRequestClose={()=>closeModal()} shouldCloseOnOverlayClick={true} style={modalStyle}>
        <ModalAlbum selected={selected} changeParentState={()=>closeModal()} />
      </Modal>
      <MediaQuery query="(max-width: 750px)">
        <div className="table console sp">
          <div className="control">
            <div className="index flex search">
              <input type="text" placeholder="アーティスト・アルバム・作曲家など" onChange={e=>filterAlbums(e)}/>
            </div>
          </div>
          <div className="lists">
            {albums && albums.map( album => (
              <AlbumList
                key={album.title_en}
                album={album}
                expanded={expanededAlbumId && expanededAlbumId === album.id ? true: false}
                resetExpanded={()=>setExpandedAlbumId()}
                onClick={(id)=>setExpandedAlbumId(id)}
                openModal={()=>openModal(album)}
                />
            ))}
          </div>{/* lists */}
        </div>{/* table sp */}
      </MediaQuery>
    </React.Fragment>
  )
}

export default Component
