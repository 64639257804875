import React from 'react'
import { Link } from 'react-scroll'

const Component = () => {
  const [isActive, setIsAcitve] = React.useState(false)
  const [isOpen , setIsOpen]  = React.useState(false)
  return (
    <header className={isActive===true && 'active'}>
      <div className="area">
        <div className="sp menu">
          <div className="button" onClick={()=>setIsAcitve(!isActive)}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div> {/* sp menu*/}
        <div className={`gnavi sp ${isActive===true && 'active'}`}>
          <div className="wrap">
            <div className="list">
              <div className="main">
                <div className="item fs_18">
                  <Link to="movie"  onClick={()=>setIsAcitve(false)}>ムービーギャラリー</Link>
                </div>
                <div className="item fs_18" >
                  <Link to="lineup" onClick={()=>setIsAcitve(false)}>ラインナップ</Link>
                </div>
                <div className="item fs_18" >
                  <Link to="artists" onClick={()=>setIsAcitve(false)}>アーティスト</Link>
                </div>
                <div className="item fs_18" >
                  <Link to="quality" onClick={()=>setIsAcitve(false)}>極HiFi CD</Link>
                </div>
                <div className="item special fs_18" onClick={()=>setIsOpen(!isOpen)}>
                  <span className={isOpen === true ? "button ico_arrow open" : "button ico_arrow"}>特設コンテンツ</span>
                </div>
              </div> {/*  main */}
              <div className="sub">
                <div className="item fs_18">
                  <Link to="selection" onClick={()=>setIsAcitve(false)}>鈴木光セレクション</Link>
                </div>
                <div className="item fs_18" >
                  <Link to="quiz" onClick={()=>setIsAcitve(false)}>鈴木光があなたに挑戦 ベスクラ・クイズ</Link>
                </div>
                <div className="item fs_18" >
                  <Link to="best" onClick={()=>setIsAcitve(false)}>著名人が選ぶベスト・クラシック100極</Link>
                </div>
                <div className="item fs_18" >
                  <Link to="recommend" onClick={()=>setIsAcitve(false)}>バイヤーおすすめタイトル</Link>
                </div>
              </div> {/*  sub */}
            </div> {/*  list */}
          </div> {/*  wrap */}
        </div> {/*  gnavi */}
      </div> {/* area*/}
    </header>
  )
}

export default Component
