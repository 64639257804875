import React from 'react'
import { BrowserRouter as Router} from "react-router-dom";
import './css/App.css';
import './css/Common.css';
import TheHeader from './components/TheHeader'
import MainVisual from './components/MainVisual'
import PageNavigation from './components/PageNavigation'
import News from './components/News'
import Campaigns from './components/Campaigns'
import Movies from './components/Movies'
import Renewal from './components/Renewal'
import Lineup from './components/Lineup'
import Playlists from './components/Playlists'
import Artists from './components/Artists'
import Quality from './components/Quality'
import Hikaru from './components/Hiraku'
import Quiz from './components/Quiz'
import Celebrities from './components/Celebrities'
import Buyers from './components/Buyers'
import TheFooter from './components/TheFooter'
// import Download from './dev/lineupDownload'
// import Update from './dev/lineupUpdate'

function App() {
  return (
    <Router>
      <div id="wrapper">
        <TheHeader/>
        <div id="layer"></div>	
        <div id="main">
          <div id="contents">
            <MainVisual/>
            <PageNavigation/>
            <News/>
            <div className="anchor" id="campaigns" />
            <Campaigns/>
            <div className="anchor" id="movie" />
            <Movies/>
            <div className="anchor" id="renewal" />
            <Renewal/>
            <div className="anchor" id="lineup" />
            <Lineup/>
            <div className="anchor" id="playlist"/>
            <Playlists/>
            <div className="anchor" id="artists" />
            <Artists/>
            <div className="anchor" id="quality" />
            <Quality/>
            <div className="anchor" id="selection" />
            <Hikaru/>
            <div className="anchor" id="quiz" />
            <Quiz/>
            <div className="anchor" id="best" />
            <Celebrities/>
            <div className="anchor" id="recommend" />
            <Buyers/>
          </div>
        </div>
        <TheFooter/>
      </div>
    </Router>
  );
}

export default App;
