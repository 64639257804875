import React from 'react'
import { Link } from 'react-scroll'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Component = () => {
  const search = useLocation().search
  const [data, setData] = React.useState()

  const getData = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
			previewKey
				? `https://bestclassic.microcms.io/api/v1/settings?draftKey=${previewKey}`
      	: 'https://bestclassic.microcms.io/api/v1/settings',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        },
      }
    )
		setData(result.data)
		console.log('Setting data were successfully downloaded')
	},[search])
	
  React.useEffect( ()=>{
    getData()
  },[getData])

  return (
    <div className="mainvisual">
      <div className="area flex -alstretch">
        <div className="panel">
          <div className="border">
            <div className="inner">
              <div className="head tac">
                {/* <div className="wave">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="img/ico_logo_bestclassic@2x.png"/>
                    <img src="img/ico_logo_bestclassic.png" alt="BEST CLASSICS 100極"/>
                  </picture>
                </div>wave */}
                <div className="the_title tac">
                  <div className="catch">
                    <p className="upper ff_shserif fs_18">クラシック音楽の世界遺産100枚<br/>4年ぶりの全面リニューアル</p>
                    <p className="lower">新技術の高品質C D「極H i F i C D 」 を採用</p>
                  </div>
                  <h1>
                    <picture>
                      <source media="(max-width: 767px)" srcSet="img/ttl_logo_mv@2x.png"/>
                      <img src="img/ttl_logo_mv.png" alt="ベスト・クラシック 100極"/>
                    </picture>
                  </h1>
                </div>{/* the_title */}
              </div>{/* head */}
              <div className="info">
                <div className="black">
                  <div className="wrap flex -spcolumn">
                    <div className="release flex -fdcolumn -alstart">
                      <div className="values flex ff_nsan">
                        <div className="v1">
                          <div className="top">前半50タイトル</div>
                          <div className="bottom fs_24">
                            <span className="text-grad">2020.11.25<span className="day min"> 発売</span></span>
                          </div>
                        </div>
                        <div className="v2">
                          <div className="top">後半50タイトル</div>
                          <div className="bottom fs_24">
                            <span className="text-grad">2020.12.09<span className="day min"> 発売</span></span>
                          </div>
                        </div>
                      </div>{/* values */}
                    </div>{/* release */}
                    <div className="price">
                      <div className="values ff_nsan">
                        <div className="v1 fs_28">
                          ￥1,600<span className="set min">+税 (1枚組)</span>
                        </div>
                        <div className="v2 fs_28">
                          ￥2,400<span className="set min">+税 (2枚組)</span>
                        </div>
                      </div>{/* values */}
                    </div>{/* price */}
                  </div>{/* wrap */}
                </div>{/* black */}
              </div>{/* info */}
              <div className="indication tac">
                <span className="i2">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="img/ico_kyoku_hifi@2x.png"/>
                    <img src="img/ico_kyoku_hifi.png" alt="極HiFi CD"/>
                  </picture>
                </span>
                <span className="i1">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="img/ico_onsho@2x.png"/>
                    <img src="img/ico_onsho.png" alt="音匠レーベル仕様"/>
                  </picture>
                </span>
                <span className="i3">
                  <picture>
                    <source media="(max-width: 767px)" srcSet="img/ico_streaming@2x.jpg"/>
                    <img src="img/ico_streaming.jpg" alt="こちらのサイトで好評配信中"/>
                  </picture>
                </span>
              </div>{/* ico */}
              <div className="links flex c3 -alstretch fs_14">
                <div className="item i1">
                  <Link to='selection' smooth={true} className="image">
                    <div className="box flex -fdcolumn -jsend">
                      <picture>
                        <source media="(max-width: 767px)" srcSet=""/>
                        <img src="img/img_links_mv_1.png" alt="鈴木光セレクション"/>
                      </picture>
                    </div>{/* box */}
                    <div className="menu fs_12 tac ff_yugo">鈴木光セレクション</div>
                  </Link>
                </div>
                <div className="item i2">
                  <Link to='quiz' smooth={true} className="image">
                    <div className="box flex -fdcolumn -jsend">
                      <picture>
                        <source media="(max-width: 767px)" srcSet=""/>
                        <img src="img/img_links_mv_2.png" alt="ベスクラ・クイズ"/>
                      </picture>
                    </div>
                    <div className="menu fs_12 ff_yugo tac"><span className="pc">鈴木光があなたに挑戦<br/></span>ベスクラ・クイズ</div>
                  </Link>
                </div>
                <div className="item i3">
                  <Link  to='best' smooth={true} className="image">
                    <div className="box flex -fdcolumn -jsend">
                      <picture>
                        <source media="(max-width: 767px)" srcSet=""/>
                        <img src="img/img_links_mv_3.png" alt="ベストクラシック"/>
                      </picture>
                    </div>{/* box */}
                    <div className="menu fs_12 tac ff_yugo">著名人が選ぶ<br/>ベスト・クラシック<span className="pc">100極</span></div>
                  </Link>
                </div>
              </div>{/* links */}
            </div>{/* inner */}     
          </div>{/* border */}
        </div>{/* panel */}
        
        <div className="image">
          <picture>
            {data && data.mainVisual && data.mainVisual.image
              ? 
              data.mainVisual.url
                ?
                <a href={data.mainVisual.url} target="_blank" without rel="noreferrer">
                  <img className="-img--noresize" src={data.mainVisual.image.url} alt="メインビジュアル"/>
                </a>
                : <img className="-img--noresize" src={data.mainVisual.image.url} alt="メインビジュアル"/>
              :  <>
                  <source media="(max-width: 767px)" srcSet="img/img_mv@2x.jpg"/>
                  <img className="-img--noresize" src="img/img_mv.jpg" alt="メインビジュアル"/>
              </>
            }
          </picture>
          {/* <div className="notation ff_shserif fs_12">イメージキャラクター 鈴木光</div> */}
        </div>{/* image */}
        {/* <div className="seals">
          <span>
            <picture>
              <source media="(max-width: 767px)" srcSet="img/ico_seal_2@2x.png"/>
              <img src="img/ico_seal_2.png" alt="SONY CLASSICAL"/>
            </picture>
          </span>
          <span>
            <picture>
              <source media="(max-width: 767px)" srcSet="img/ico_seal_1@2x.png"/>
              <img src="img/ico_seal_1.png" alt="RCA RED SEAL"/>
            </picture>
          </span>
        </div>seals */}
        
        <div className="cover">
          <picture>
            <source media="(max-width: 767px)" srcSet="img/bg_mv@2x.svg"/>
            <img className="-img--noresize" src="img/bg_mv.svg" alt="カバー"/>
          </picture>
        </div>{/* cover */}
      
      </div>{/* area */}
    </div>
  )
}

export default Component
