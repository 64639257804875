import React from 'react'
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Title from './SectionTitle'

const Component = () => {
  const search = useLocation().search
  const [data, setData] = React.useState()

  const getData = React.useCallback(async()=>{
    const isPreview = queryString.parse(search).preview
    const result = await axios.get(
			isPreview
				? `https://bestclassic.microcms.io/api/v1/settings?draftKey=aFE9DpEoB`
      	: 'https://bestclassic.microcms.io/api/v1/settings',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        },
      }
    )
		setData(result.data)
		console.log('Setting data were successfully downloaded')
	},[search])
	
  React.useEffect( ()=>{
    getData()
  },[getData])

  return (
    <>
      {data && data.campaigns && 
        <section className="campaigns">
        <Title ja='キャンペーン' en='Campaigns' />
          <ul className="inner1024 list">
            {data.campaigns.map( (c, i) =>
            c.url
              ?
              <li>
                <a href={c.url} target="_blank" without rel="noreferrer" key={`キャンペーン${i}`}>
                  <img src={c.image.url} alt={`キャンペーン${i}`} className='campaign' key={`キャンペーン${i}`}/>
                </a>
                </li>
              :
               <li><img src={c.image.url} alt={`キャンペーン${i}`} className='campaign' key={`キャンペーン${i}`}/></li>
            )}
          </ul>
        </section>
      }
    </>
  )
}

export default Component
