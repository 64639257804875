import React from 'react'
import { QuizContext } from "../services/QuizProvider";

const Component = () => {
  const [quizContents,setQuizContents] = React.useContext(QuizContext)
  const [isFirstTime, setisFirstTime] = React.useState(true)
  const [showAnswer, setShowAnswer] = React.useState(false)
  const [yourAnswer, setYourAnswer] = React.useState()
  const [randomQ, setRandomQ] = React.useState()
  const [view, setView] = React.useState()

  const createQuizHead = (count, q) => {
    return (
      <div className="head">
        <div className="commonHead tac">
          <div className="en fs_18 ff_shserif text-grad gold2">Q.{count}</div>
          <div className="ja fs_24">{q.title}</div>
        </div>{/* commonHead */}
      </div>
    )
  }

  const createAnswer = React.useCallback( (count, q, total) => {
    const result = yourAnswer === q.answer
      ? true
      : false
    return (
      <div className="a correct container">
        {createQuizHead(count,q)}
        <div className="select">
          <div className={yourAnswer === 1 ? 'selected question-btn q1' : 'disabled question-btn'}>
            <div className="border after flex -jsstart">
              <div className="num">A.</div>
              <div className="choise">{q.q1}</div>
            </div>
          </div>
          <div className={yourAnswer === 2? 'selected question-btn q2' : 'disabled question-btn'}>
            <div className="border after flex -jsstart">
              <div className="num">B.</div>
              <div className="choise">{q.q2}</div>
            </div>
          </div>
          <div className={yourAnswer === 3 ? 'selected question-btn q3' : 'disabled question-btn'}>
            <div className="border after flex -jsstart">
              <div className="num">C.</div>
              <div className="choise">{q.q3}</div>
            </div>
          </div>
        </div>{/* select */}
        { result === true
          ? 
          <div className="result tac">
            <div className="display ff_shserif">○ 正解</div>
          </div>
          :
          <div className="result tac">
            <div className="display ff_shserif">× 不正解</div>
            <div className="note">{`正解は${q.answer===1 ? 'A': q.answer===2 ? 'B': 'C'}の「${q['q'+q.answer]}`}」でした</div>
          </div>
        }
        <div className="pager">
          <div
          className='next-btn ff_shingo fs_20'
          onClick={()=>{
            const newResult = quizContents.results.length === 0
              ? [result]
              : quizContents.results.concat([result])
            setYourAnswer()
            setQuizContents(q => ({ ...q, results:newResult }))
            setShowAnswer(false)
          }}>
            <span className="ico_arrow">
              {count < total
              ? '次の問題へ'
              : 'クイズの成績を見る'
            }
            </span>
          </div>
        </div>
      </div>
    )
  },[yourAnswer, quizContents.results, setQuizContents])

  const createQuestion = React.useCallback((count, q) => {
    return (
      <div className="q container">
        {createQuizHead(count,q)}
        <div className="select">
          <div
            onClick={()=>{
              setShowAnswer(true)
              setYourAnswer(1)
            }} className='question-btn q1'>
            <div className="border after flex -jsstart">
              <div className="num">A.</div>
              <div className="choise">{q.q1}</div>
            </div>
          </div>
          <div
            onClick={()=>{
              setShowAnswer(true)
              setYourAnswer(2)
            }} className='question-btn q2'>
            <div className="border after flex -jsstart">
              <div className="num">B.</div>
              <div className="choise">{q.q2}</div>
            </div>
          </div>
          <div
            onClick={()=>{
              setShowAnswer(true)
              setYourAnswer(3)
            }} className='question-btn q3'>
            <div className="border after flex -jsstart">
              <div className="num">C.</div>
              <div className="choise">{q.q3}</div>
            </div>
          </div>
        </div>{/* select */}
      </div>
    )
  },[])

  React.useEffect( ()=> {
    if (quizContents.questions) {
      const now = quizContents.results.length
      if(isFirstTime === true) {
        const questionDataOfThisLevel = quizContents.questions.filter( q => q.level_id === quizContents.level)
        const total = questionDataOfThisLevel[0].random
        console.log('total', total)
        const shuffledQuestions = questionDataOfThisLevel[0].questions.sort(() => 0.5 - Math.random());
        const randomedQuestions = shuffledQuestions.slice(0, total);
        console.log('randomedQuestions', randomedQuestions)
        setRandomQ(randomedQuestions)
        setisFirstTime(false)
      }
      if(randomQ){
        if( now < randomQ.length) {
          const questionData = randomQ[now]
          const viewContents = showAnswer === false
            ? createQuestion(now+1, questionData)
            : createAnswer(now+1, questionData, randomQ.length)
          setView(viewContents)
        } else {
          setQuizContents(q => ({ ...q, step: quizContents.step + 1 }))
        }
      }
    } 
  },[quizContents.results, quizContents.level, quizContents.questions, createAnswer, createQuestion, showAnswer, setQuizContents, randomQ, isFirstTime, quizContents.step])

  return (
    <React.Fragment>
      {view}
    </React.Fragment>
  )
}

export default Component