import React from "react";

// contexts
export const QuizContext = React.createContext([null, ()=>{}]);

export const initialState = {
  step: 0,
  level: null,
  questions: [],
  results: []
};

// component
const Provider = props => {  
  const [quizContents, setQuizContents] = React.useState(initialState);
  return (
    <QuizContext.Provider
      value={[quizContents, setQuizContents]}
    >
      {props.children}
    </QuizContext.Provider>
  );
};

export default Provider;
