export const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    display: 'grid',
    'placeItems': 'center'
  },
  content: {
    position: "absolute",
    backgroundColor: 'white',
    maxHeight: '100vh',
  }
}; 