import React from 'react'
import SelectLevel from './ModalQuizLevel'
import AskQuestions from './ModalQuizQuestion'
import Result from './ModalQuizResult'
import axios from 'axios'
import { QuizContext } from "../services/QuizProvider";

const Component = ({changeParentState})=> {
  const [view, setView] = React.useState(<SelectLevel/>)
  const [quizContents,setQuizContents] = React.useContext(QuizContext)

  const closeModal = React.useCallback(() => {
    window.confirm('クイズ画面を閉じてよろしいですか？\n現在のクイズの内容はリセットされます') === true &&
      changeParentState(false)
  },[changeParentState])

  const getQuestionsData = React.useCallback( async()=> {
    const data = await axios.get(
      'https://bestclassic.microcms.io/api/v1/quiz',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    console.log('Quiz data were successfully downloaded.', data.data.contents)
    setQuizContents(q => ({...q, questions: data.data.contents}))
  },[setQuizContents])

  React.useEffect( ()=> {
    console.log(`step: ${quizContents.step}` )
    switch(quizContents.step) {
      default :
        break
      case 0 :
        getQuestionsData()
        setView(<SelectLevel/>)
        break
      case 1:
        setView(<AskQuestions/>)
        break
      case 2:
        setView(<Result closeModal={()=>closeModal()}/>)
    }
  },[closeModal, getQuestionsData, quizContents.step])

  return (
    <div data-remodal-id="bestclassicquiz"  className='remodal'>
      <button onClick={() => closeModal()} data-remodal-action="close" className="modal_close before after"/>
      {view}
    </div>
  )
}

export default Component