import React from 'react'
// API
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal'
import ModalAlbum from './ModalAlbum'
import AlbumList from './AlbumListSP'
import MediaQuery from "react-responsive";
import {modalStyle} from '../css/ModalStyle'

Modal.setAppElement("#root");

const Component = () => {
  const search = useLocation().search
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [selected, setSelected] = React.useState()
  const [data, setData] = React.useState()
  const [expanededAlbumId, setExpandedAlbumId] = React.useState()

  const closeModal = () => {
    setIsOpen(false)
    document.body.removeAttribute('style', 'overflow: hidden;')
  }

  const openModal = album => {
    setSelected(album)
    setIsOpen(true)
    document.body.setAttribute('style', 'overflow: hidden;')
  }

  const toggleModal = album => {
    modalIsOpen === true
      ? closeModal()
      : openModal(album)
  }

  const getData = React.useCallback(async()=>{
    const previewKey = queryString.parse(search).preview
    const result = await axios.get(
			previewKey
				? `https://bestclassic.microcms.io/api/v1/hikaru?draftKey=${previewKey}`
      	: 'https://bestclassic.microcms.io/api/v1/hikaru',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      }
    )
    setData(result.data)
    console.log('Hikaru data were successfully downloaded.')
  },[search]) 

  React.useEffect( ()=>{
    getData()
  },[getData])

  return (
    <React.Fragment>
      <section className="hikaru">
        <div className="commonHead tac ff_nsan">
          <div className="inner1024">
            <div className="sub">
              <p className="bestclassic"><img src="img/ttl_logo_mv.png" alt="ベスト・クラシック100極"/></p>
              <p className="imagecharacter">イメージキャラクター</p>
            </div>
            <div className="ruby">すずき ひかる</div>
            <div className="ja fs_64"><h2><span className="border after">鈴木光</span></h2></div>
          </div>{/* inner1024 */}
        </div>{/* commonHead */}
        
        <div className="body">
          
          <div className="baloon ff_nsan">
            <div className="inner after tac fs_24">
              <h3>{data && data.comment}</h3>
            </div>
          </div>{/* baloon */}
          
          <div className="listwrap wrap flex -alstretch -jsstart -spcolumn">
            <div className="item image">
              <div className='profile'>
                <div className='text'>
                  <div className='name ff_yugo fs_14'>{data && data.name}</div>
                  <p className='ff_yugo fs_12'>{data && data.profile}</p>
                </div>
              </div>
            </div>
            <div className="item selection flex -fdcolumn -alstretch">
              <div className="list pc">
                {data && data.selections.map( item => (
                  <div
                    key={item.album.title_en}
                    className="item"
                    onClick={()=>{
                    toggleModal(item.album)
                  }}>
                    <div className="each flex -jsstart">
                        {item.album.image && 
                        <picture className="thumbnail">
                          <source media="(max-width: 767px)" srcSet={( item.album.image && item.album.image.url+'?fit=max&w=120&h=120' ) || "img/img_artist_1@2x.jpg"}/>
                          <img src={item.album.image.url+'?fit=max&w=60&h=60' || "img/img_thumbnail_hikaru.png"} alt={item.album.title}/>
                        </picture>
                        }
                      <div className="num fs_14 ff_yugo">{item.album.number}</div>
                      <div className="info ff_shserif">
                        <div className="title">{item.album.artist}</div>
                        <div className="lower flex">
                          <div className="name fs_12">{item.album.title}</div>
                        </div>{/* lower */}
                      </div>{/* info */}
                      <div className="play tac ff_nsan fs_14">
                        <a href={item.album.url} target= '_blank' rel='noopener noreferrer' >再生・購入</a>
                      </div>
                    </div>
                    <div className="description fs_14 ff_yugo">
                      {item.comment}
                    </div>
                  </div>
                ))}
              </div>{/* list */}

              <MediaQuery query="(max-width: 750px)">
                <div className="table console sp">
                  {/* <div className="control">
                    <div className="index flex search">
                      <input type="text" placeholder="アーティスト・アルバム・作曲家"/>
                    </div>
                  </div> */}
                  <div className="lists">
                    {data && data.selections.map( item => (
                      <AlbumList
                        expanded={expanededAlbumId && expanededAlbumId === item.album.id ? true: false}
                        resetExpanded={()=>setExpandedAlbumId()}
                        onClick={(id)=>setExpandedAlbumId(id)}
                        key={item.album.title_en}
                        album={item.album}
                        openModal={()=>openModal(item.album)} comment={item.comment}/>
                    ))}
                  </div>{/* lists */}
                </div>{/* table sp */}
              </MediaQuery>
              
              <div className="table console sp">
                <div className="lists">
                </div>{/* lists */}
              </div>{/* table sp */}
              
              {data && data.playlist &&
              <div className="playlist tac">
                <div className="appeal fs_14 ff_nsan">
                  鈴木光さんおすすめの曲がプレイリストになりました。
                  <span className="pc"><br/>本人のおすすめコメントとともにお楽しみください。</span>
                </div>
                <div className="link ff_nsan fs_14">
                  <a href={data.playlist} target= '_blank' rel='noopener noreferrer' >プレイリストで聴く</a>
                </div>
              </div>
              }
            </div>{/* selection */}
          </div>{/* wrap */}
          
        </div>{/* body */}
        
        <div className="video">
          <div className="wrap flex -spcolumn">
            {data && data.movies.map( movie => (
              <div className="item" key={movie.title}>
                <div className="label tac fs_14"><h3>{movie.title}</h3></div>
                <div className="cont">
                  <iframe width="560" height="315" src={movie.url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={movie.title} />
                </div>
              </div>
            ))}
          </div>{/* wrap */}
        </div>{/* video */}
      </section>
      <Modal isOpen={modalIsOpen} className="modal_data" onRequestClose={()=>closeModal()} shouldCloseOnOverlayClick={true} style={modalStyle}>
        <ModalAlbum selected={selected} changeParentState={()=>closeModal()} />
      </Modal>
    </React.Fragment>
  )
}

export default Component
