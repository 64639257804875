import React from 'react'
import { Link } from 'react-scroll'
import {Collapse} from 'react-collapse'

const Component = () => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <nav className={expanded===true ? "pagenavi ff_shserif tac pc open": "pagenavi ff_shserif tac pc"}>
      <div className="inner1024">
        <div className="wrap top flex">
          <div className="item">
            <Link to='movie' smooth={true}>ムービーギャラリー</Link>
          </div>
          <div className="item">
            <Link to='lineup' smooth={true}>ラインナップ</Link>
          </div>
          <div className="item">
            <Link to='artists' smooth={true}>アーティスト</Link>
          </div>
          <div className="item">
            <Link to='quality' smooth={true}>極HiFi CD</Link>
          </div>
          <div className="item special">
            <span onClick={()=>setExpanded(!expanded)}><span className="ico_arrow">特設コンテンツ</span></span>
          </div>
        </div>{/* wrap */}
        <Collapse  isOpened={expanded} className="wrap bottom flex">
          <div className="item">
            <Link to="selection" smooth={true}>鈴木光セレクション</Link>
          </div>
          <div className="item">
            <Link to="quiz" smooth={true}>ベスクラ・クイズ</Link>
          </div>
          <div className="item">
            <Link to="best" smooth={true}>著名人が選ぶベスト・クラシック100極</Link>
          </div>
          <div className="item">
            <Link to="recommend" smooth={true}>バイヤーおすすめタイトル</Link>
          </div>
        </Collapse>{/* wrap */}
      </div>{/* innr1024 */}
    </nav>
  )
}

export default Component
