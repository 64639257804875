import React from 'react'

const Component = ({album, openModal, comment, onClick, expanded, resetExpanded}) => {
  const returnAlbumDate = () => {
    return openModal(album)
  }
  const returnId = (id) => {
    return onClick(id)
  }
  const reset = () => {
    return resetExpanded()
  }
  return (
    <div className={expanded === true ? "song selected" : "song"} >
      <div
        className="each flex -alstretch -jsstart"
        onClick={ ()=> expanded === true
          ? reset()
          : returnId(album.id)
        }>
        <div className="head tac">
          <div className="thumbnail"><img src={album.image.url+'?fit=full&w=96&h=96' || "img/img_thumbnail_lineup.png"} alt={album.title}/></div>
          <span className="num fs_12 ff_yugo">{album.number}</span>
        </div>
        <div className="value">
          <div className="title">
            <div className="ja"><h4>{album.artist}</h4></div>
          </div>
          <div className="name">
            <div className="ja"><h3>{album.title}</h3></div>
          </div>
          {comment &&
            <div className="name">
            <div className="ja ff_yugo"><p>{comment}</p></div>
            </div>
          }
        </div>
      </div>{/* each */}
      <div className="bottom">
        <div className="value flex -jscenter">
          <div className="play tac ff_nsan fs_14">
            {album.url && <a href={album.url} target= '_blank' rel='noopener noreferrer' >再生・購入</a>}
          </div>
          <div className="detail tac ff_nsan fs_14 btn">
            <span data-modal-open-id="lineup" onClick={()=>returnAlbumDate()}>詳細</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component