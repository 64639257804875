import React from 'react'
import axios from 'axios'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Component = () => {
  const search = useLocation().search
  const [data, setData] = React.useState()

  const getData = React.useCallback(async()=>{
    const isPreview = queryString.parse(search).preview
    const result = await axios.get(
			isPreview
				? `https://bestclassic.microcms.io/api/v1/settings?draftKey=aFE9DpEoB`
      	: 'https://bestclassic.microcms.io/api/v1/settings',
      {
        headers:{
          'X-API-KEY': process.env.REACT_APP_API_KEY
        },
      }
    )
		setData(result.data)
		console.log('Setting data were successfully downloaded')
	},[search])
	
  React.useEffect( ()=>{
    getData()
  },[getData])

  return (
    <footer>
			<div className="inner1024">
				<div className="wrap tac">
					<div className="logo">
						<picture>
							<source media="(max-width: 767px)" srcSet="img/ico_logo_bestclassic@2x.png"/>
							<img src="img/ico_logo_bestclassic.png" alt="BEST CLASSICS 100 極"/>
						</picture>
					</div>
					<div className="title fs_12 ff_yugo">ベスト・クラシック100極</div>
						{ (data && data.banner) && 
							<a className='banner' href={data.banner.url} target= '_blank' rel='noopener noreferrer' >
								<img className="-img--noresize" src={data.banner.image.url} alt="instagram"/>
							</a>
						}
					<div className="link ff_yugo">
						<a href="https://www.sonymusic.co.jp/privacy/" target= '_blank' rel='noopener noreferrer' >プライバシーポリシー</a>
					</div>
					<div className="sns">
						<div className="container flex -jscenter">
							{ (data && data.twitter) && 
								<div className="item"><a href={data.twitter} target= '_blank' rel='noopener noreferrer' ><img className="-img--noresize" src="img/ico_sns_twitter.svg" alt="twitter"/></a></div>
							}
							{ (data && data.facebook) && 
								<div className="item"><a href={data.facebook} target= '_blank' rel='noopener noreferrer' ><img className="-img--noresize" src="img/ico_sns_facebook.svg" alt="facebook"/></a></div>
							}
							{ (data && data.instagram) && 
								<div className="item"><a href={data.instagram} target= '_blank' rel='noopener noreferrer' ><img className="-img--noresize" src="img/ico_sns_instagram.svg" alt="instagram"/></a></div>
							}
						</div>
					</div>
					<p className='copyright'>Copyright © 2020 Sony Music Japan International All Rights Reserved</p>
				</div>{/* wrap */}
			</div>{/* inner1024 */}
		</footer>
  )
}

export default Component
